@import url(https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap);
.react-header {
  font-family: "Rubik Mono One", monospace;
  font-weight: 400;
  font-style: normal;
}
.pageContent {
  background:#6c6c6c
}

body {
  font-family: Roboto, sans-serif;
  margin: 0;
}
.instructions {
  padding:0 100px 0 15px
}
.instructions li {
  padding-bottom: 8px;
  line-height: 22px;
}
.MuiDialogActions-root button {
  color: black !important;
}
.ReactTable {
  box-sizing: border-box;
}

/* table color styles */
.ReactTable .rt-pivot,
.ReactTable .rt-pivot ~ div {
  background: #0000001c;
  border-bottom: 1px solid #a0a0a0;
}

.ReactTable.-blacklist .rt-tr.-odd {
  background: #212121;
}
.ReactTable.-blacklist .rt-tr.-even {
  background: #424242;
}
.ReactTable.-blacklist .rt-tbody .rt-tr:not(.-padRow):hover {
  background: #616161;
}
.ReactTable.-blacklist .rt-td {
  color: #fff;
  border-color: #ffffff12;
  text-overflow: clip;
}
.ReactTable.-blacklist .rt-th {
  color: #000;
}
.ReactTable.-blacklist .rt-tr-group {
  border: 0;
}

.ReactTable.-blacklist .mapStatus > div {
  width: 100%;
}

.ReactTable.-blacklist .mapStatus svg {
  color: #fff;
}
.ReactTable.-blacklist .mapStatus > div > div:before {
  border-bottom: none;
}

.ReactTable.-blacklist .mapStatus > div > div > div > div {
  padding: 0 20px 0 0;
  text-align: left;
  color: white;
}

.key {
  padding: 0 0 10px 10px;
}
.key h2 {
  margin: 10px 0 5px;
}
.key h3 {
  margin: 10px 0 5px;
}
.key span {
  margin-left: 5px;
}

.purchasing-stock {
  background: #4db6ac;
}
.purchasing-stock .rt-th {
  background: #80cbc4;
}
.purchasing-stock .rt-th.rt-resizable-header {
  background: #b2dfdb;
}

.blue {
  background: #b2dfdb !important;
}
.lblue {
  background: #e0f2f1 !important;
}
.blue-header {
  background: #80cbc4 !important;
}
.blue-masterheader {
  background: #4db6ac !important;
}
.orange {
  background: #ffe0b2 !important;
}
.orange-header {
  background: #ffcc80 !important;
}
.orange-masterheader {
  background: #ffb74d !important;
}
.green {
  background: #dcedc8 !important;
}
.green-header {
  background: #c5e1a5 !important;
}
.green-masterheader {
  background: #aed581 !important;
}

.approve {
  background: #bdbdbd;
}
.approve-header {
  background: #9e9e9e;
}

.discogs-subtable {
  border-bottom: solid 2px #ffd54f;
}
.discogs-subtable .rt-thead {
  background: #ffd54f;
}
.discogs-subtable .rt-th {
  font-weight: bold;
}
/* table color styles END */

.pageContent {
  position: absolute;
  top: 45px;
  left: 0;
  right: 0;
  bottom: 0;
}
.pageContent .page {
  height: calc(100% - 40px);
  overflow-y: scroll;
  padding: 20px 0;
}
.rt-th:focus {
  outline: none;
}
.orderQty > div {
  outline: none;
}
.fraudHeader {
  font-weight: bold;
}
.FraudDetail {
  text-align: left;
  text-align: initial;
  font-size: 14px;
  padding: 15px 40px;
  display: flex;
  flex-wrap: wrap;
}
.FraudDetail p {
  margin: 8px 0;
  line-height: 24px;
}
.FraudDetail ul {
  margin: 0;
  padding-left: 20px;
}
.FraudApproval {
  padding: 0 38px 10px;
  display: flex;
  justify-content: flex-start;
}
.FraudApproval button {
  margin-right: 25px;
  margin-bottom: 10px;
}
.FraudApproval > * {
  margin-right: 30px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.FraudApproval > *:last-child {
  margin-right: 0;
}
.FraudApproval > span > *:first-child {
  margin-right: 10px;
}
.DecisionIcon {
  display: flex;
  flex-direction: column;
}
.decisionDetails {
  text-align: left;
  align-self: flex-end;
}
.cardBlock {
  width: 115px;
  margin-right: 35px;
}
.cardBlock p span {
  float: right;
}
.pass-fail {
  font-weight: bold;
}
.addressBlock {
  min-width: 180px;
  max-width: 240px;
  margin-right: 20px;
}
.addressBlock p {
  text-overflow: ellipsis;
}
.historyBlock {
  max-width: 200px;
  margin-right: 30px;
}
.otherBlock {
  max-width: 280px;
}

.demon {
  margin: 11px 0;
}

.dash h3 {
  margin-bottom: 0;
  margin-left:20px

}
.dash p {
  margin-top:8px;
  margin-left:20px
}

@media only screen and (min-width: 1100px) {
  .FraudDetail {
    font-size: 14px;
  }
}

